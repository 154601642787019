<template>
  <div style="padding-bottom: 80px;">
    <!-- <you-lost-access-sidebar
      :show-open-you-lost-access-sidebar.sync="showOpenYouLostAccessSidebar"
      :url="idsite.domain"
    /> -->
    <!-- <sidebar-ownership
      :idsite="idsite"
      :show-open.sync="showOpen"
    />
    <change-project
      :idsite="idsite"
      :show-open-change-project.sync="showOpenChangeProject"
    /> -->
    <archive-site
      :idsite="idsite"
      :show-open-archive-site.sync="showOpenArchiveSite"
    />
    <activate-site
      :idsite="idsite"
      :show-open-archive-site.sync="showOpenActiveSite"
    />
    <info-license
      :idsite="idsite"
      :user-data.sync="userData"
      :show-open-info-license.sync="showOpenInfoLicense"
    />
    <success-change-license
      :idsite="idsite"
      :user-data.sync="userData"
      :show-open-success-license.sync="showOpenSuccessLicense"
    />
    <cancel-subscription
      :idsite="idsite"
      :user-data.sync="userData"
      :show-open-cancel-subscription.sync="showOpenCancelSubscription"
    />
    <reactivate-subscription
      :idsite="idsite"
      :user-data.sync="userData"
      :show-open-reactivate-subscription.sync="showOpenReactivateSubscription"
    />
    <coming-soon
      :idsite="idsite"
      :show-open-coming-soon.sync="showOpenComingSoon"
    />
    <info-subscription
      :idsite="idsite"
      :show-open-subscription.sync="showOpenSubscription"
    />
    <!-- <members-project
      :idsite="idsite"
      :show-open-members-project.sync="showOpenMembersProject"
    /> -->

    <b-overlay
      :show="loadingSitesLicense || loadingSites"
      rounded="xl"
      no-fade
    >
      <div class="w-100" style="height: 50px;">
        <b-input-group
          class="input-group-merge search-input-license mr-2 mb-1 float-right"
          style="width: 350px; height: 38px; border: 1px solid #A7AAB9; border-radius: 22px; "
        >
          <b-input-group-prepend style="border: transparent; border-radius: 20px;">
            <div
              class="input-group-text"
              style="border: transparent; border-radius: 20px;"
            >
              <feather-icon icon="SearchIcon" />
            </div>
          </b-input-group-prepend>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            style="border: transparent; background: none; height: 100%;"
          />
        </b-input-group>
        <h2
          class="float-left mb-0 amount-license"
          style="font-weight: 700; color: #7838ff !important; text-align: left; font-size: 28px !important;"
        >
          {{ counterSites }} 
          <span style="font-weight: initial; color: #656B85; font-size: 1rem !important;">sites</span>
        </h2>
      </div>

      <div :class="'top-scroll-horizontal-license'+(type === 'Free' ? type : 'Pro')" style="overflow-x: scroll; width: 100%;"><div class="div1" :id="'tab-err-sc2'+(type === 'Free' ? type : 'Pro')" style="height: 20px;"></div></div>
      <vue-good-table
        :columns="type === 'Free' ? columnsFree : columns"
        :rows="searchTerm ? rowsSitesLazy : rowsSites"
        :rtl="direction"
        id="tab-err-sc1"
        :style-class="'vgt-table condensed bordered vgt-table bordered striped table-wproject-sites-license'+(type === 'Free' ? type : 'Pro')"
        :sort-options="{
          enabled: false,
        }"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        class="table-show-license my-2"
        :emptyTableMessage="''"
        :pagination-options="{enabled: false}"
        @on-cell-click="onRowSelected"
      >
        <div slot="emptystate">
        </div>
        <template
          slot="table-header-row"
          slot-scope="props"
          class="p-0"
        >
          <div
            :style="`background: ${props.row.color};`"
            class="mr-50"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field == 'imgSite'"
            style="padding-top: 40px !important;"
          >
            <b-link 
              :to="{
                name: 'view-errors',
                params: {
                id: props.row.id, imgSite: props.row.imgSite, name: props.row.name, url: props.row.domain, project: props.row.projectid, own: rowsSites[0].permission === 'owner' ? 'me' : 'other'
                },
              }"
              class="w-100"
              style="height: 100% !important; display: block;"
            >
              <b-img-lazy
                :src="props.formattedRow['imgSite']"
                onerror="this.onerror=null;this.src='https://src.seocloud.ai/site-thumb.png';"
                width="120px"
                height="70px"
                class="my-50"
                loading="lazy"
                :alt="'img not found'"
              />
            </b-link>
          </span>
          <span v-else-if="props.column.field == 'name'">
            {{props.row.name}}
            <br>
            <b-button
              variant="secondary"
              style="background: #F0F1F7 0% 0% no-repeat padding-box !important; border: 1px solid #CFD3E5 !important; color: #656B85 !important; padding: 2px 7px; border-radius: initial;"
              class="p-auto mr-50"
              v-if="props.row.statusSite === 'archive'"
            >
              <feather-icon
                icon="ArchiveIcon"
                class="mr-50"
              />
              <span>Archived</span>
            </b-button>
          </span>
          <span v-else-if="props.column.field == 'domain'">
            <b-link 
              :to="{
                name: 'view-errors',
                params: {
                id: props.row.id, imgSite: props.row.imgSite, name: props.row.name, url: props.row.domain, project: props.row.projectid, own: rowsSites[0].permission === 'owner' ? 'me' : 'other'
                },
              }"
              class=""
              style=""
            >
              <span class="" :style="props.row.crawl && props.row.crawl.estatus === 'running' ? 'top: -12px; position: relative; font-size: 15px; color: #A7AAB9;' : 'font-size: 15px; color: #A7AAB9;'">{{ props.row['domain'] }}</span>
            </b-link>
          </span>
          <span v-else-if="props.column.field == 'plan'">
            <b-dropdown
              v-if="props.formattedRow['plan'] !== 'Free'"
              :text="props.formattedRow['plan']"
              :style="rowsSites.length >= 1 && (rowsSites[0].permission === 'owner' || rowsSites[0].permission === 'admin' || rowsSites[0].permission === 'edit') ? 'cursor: pointer !important;' : 'cursor: not-allowed !important;'"
              variant="outline-primary"
              class="style-chooser mb-25 ml-0"
              :class="props.formattedRow['plan'] === 'Free' ? 'style-chooser mb-25' : 'style-chooser mb-25'"
              :disabled="rowsSites.length >= 1 && (rowsSites[0].permission === 'owner' || rowsSites[0].permission === 'admin' || rowsSites[0].permission === 'edit') ? false : true"
            >
              <template #button-content>
                <b-img-lazy
                  v-if="props.formattedRow['plan'] !== 'Free'"
                  fluid
                  :src="proIcon"
                  alt="pro page img"
                  width="17"
                  height="16"
                  style="margin-bottom: 7px;"
                  class="mr-0"
                  loading="lazy"
                />
                <!-- {{ props.formattedRow['plan'] }} -->
                Licensed
              </template>
              <b-dropdown-item style="width: 180px;"
                @click.prevent.stop="props.row.cancelSub === true && props.row.nextDue && props.row.subscriptionId && props.formattedRow['plan'] !== 'free' ? logIdReactivateSubscription(props.row, 'upgrade') : isDevSite ? logIdComingSoon(props.row) : logIdLicense(props.row)"
              >
                <span style="font-size: 15px; color: #656B85;">
                <feather-icon
                  icon="ArrowUpIcon"
                  class="mt-0 pt-0"
                  size="12"
                />
                Upgrade
                </span><br>
                <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 10px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
              </b-dropdown-item>
              <b-dropdown-item style="width: 180px;" @click.prevent.stop="props.row.cancelSub === true && props.row.nextDue && props.row.subscriptionId && props.formattedRow['plan'] !== 'free' ? logIdReactivateSubscription(props.row, 'upgrade') : isDevSite ? logIdComingSoon(props.row) : logIdLicense(props.row)">
                <span style="font-size: 15px; color: #656B85;">
                <feather-icon
                  icon="ArrowDownIcon"
                  class="mt-0 pt-0"
                  size="12"
                />
                  Downgrade
                </span><br>
                <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 10px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-button
              variant="outline-secondary"
              block
              class="px-0 py-25"
              :style="rowsSites.length >= 1 && (rowsSites[0].permission === 'owner' || rowsSites[0].permission === 'admin' || rowsSites[0].permission === 'edit') ? 'cursor: pointer !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin-left: 8px;' : 'cursor: not-allowed !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin-left: 8px;'"
              @click.prevent.stop="props.row.cancelSub === true && props.row.nextDue && props.row.subscriptionId && props.formattedRow['plan'] !== 'free' ? logIdReactivateSubscription(props.row, 'upgrade') : isDevSite ? logIdComingSoon(props.row) : logIdLicense(props.row)"
              :disabled="rowsSites.length >= 1 && (rowsSites[0].permission === 'owner' || rowsSites[0].permission === 'admin' || rowsSites[0].permission === 'edit') ? false : true"
              pill
            >
              Upgrade
            </b-button>
          </span>
          <span v-else-if="props.column.field == 'status'">
            <div v-if="props.row.freeTrial === true && props.row.subscriptionId">
              <b-badge variant="light-success" class="cursor-pointer">
                Active
              </b-badge><br>
              <b-badge class="mt-50 cursor-pointer" style="font-size: 11px;" variant="light-dark" @click.prevent.stop="logIdInfoSubscription(props.row)">
                <span style="color: #646464 !important">On trial</span>
              </b-badge>
            </div>
            <div v-else-if="props.row.failPay === true && props.row.nextDue && props.row.subscriptionId">
              <b-badge variant="light-success" class="cursor-pointer" @click.prevent.stop="logIdReactivateSubscription(props.row, 'test')">
                Active
              </b-badge>
              <b-badge class="mt-50 cursor-pointer" style="font-size: 11px;" variant="light-dark" @click.prevent.stop="logIdReactivateSubscription(props.row, 'test')" v-if="props.row.nextDue !== '-'">
                <span style="color: #646464 !important">Downgrade: payment failed</span>
                <!-- <feather-icon
                  icon="ClockIcon"
                  class="ml-25"
                  style="padding-bottom: 1px;"
                /> -->
              </b-badge>
            </div>
            <div v-else-if="props.row.cancelSub === true && props.row.nextDue && props.row.subscriptionId">
              <b-badge variant="light-warning" class="cursor-pointer" @click.prevent.stop="logIdReactivateSubscription(props.row, 'test')">
                Canceled
              </b-badge>
              <b-badge class="mt-50 cursor-pointer" style="font-size: 11px;" variant="light-dark" @click.prevent.stop="logIdReactivateSubscription(props.row, 'test')" v-if="props.row.nextDue !== '-'">
                <span style="color: #646464 !important">Cancels {{props.row.nextDueCancel}}</span>
                <feather-icon
                  icon="ClockIcon"
                  class="ml-25"
                  style="padding-bottom: 1px;"
                />
              </b-badge>
            </div>
            <div v-else-if="props.row.downgrade_planed === true && props.row.nextDueCancel && props.row.subscriptionId">
              <b-badge variant="light-info" style="text-transform: capitalize;" class="cursor-pointer" @click.prevent.stop="logIdReactivateSubscription(props.row, 'test')">
                {{props.row.status}}
              </b-badge>
              <b-badge class="mt-50 cursor-pointer" style="font-size: 11px;" variant="light-dark" @click.prevent.stop="logIdReactivateSubscription(props.row, 'upgrade')" v-if="props.row.nextDueCancel !== '-'">
                <span style="color: #646464 !important">Downgrades {{props.row.nextDueCancel}}</span>
                <feather-icon
                  icon="ClockIcon"
                  class="ml-25"
                  style="padding-bottom: 1px;"
                />
              </b-badge>
            </div>
            <b-badge variant="light-success" style="text-transform: capitalize;" v-else>
                {{props.row.status}}
            </b-badge>
          </span>
          <span v-else-if="props.column.field == 'pages'">
            <b-link
              @click.prevent.stop="props.row.cancelSub === true && props.row.nextDue && props.row.subscriptionId && props.formattedRow['plan'] !== 'free' ? logIdReactivateSubscription(props.row, 'test') : isDevSite ? logIdComingSoon(props.row) : logIdLicense(props.row)"
            >
              {{props.row.pages}}
            </b-link>
          </span>
          <span v-else-if="props.column.field == 'nextDue'">
            {{props.formattedRow['plan'] === 'Free' ? '-' : props.row.nextDue}}
          </span>
          <span v-else-if="props.column.field == 'currentplan'" style="text-transform: capitalize;">
            {{ props.formattedRow['plan'] !== 'Free' && props.formattedRow['plan'] !== 'free' ? props.row.paidRecurrency : type }}
          </span>
          <span v-else-if="props.column.field == 'amount'">
            <p style="text-align: center;" class="mb-0">{{ getPlanAmount(props.row.paidRecurrency,props.row.planType,props.row.planType) }}</p>
            <p style="text-align: center; color: rgb(168, 168, 168); font-size: 13px;" class="mb-0" v-if="props.row.paidRecurrency === 'Anually'">Billed anually</p>
          </span>
          <span v-else-if="props.column.field == 'payments'">
            <b-overlay
              :show="props.row.customerIdSite ? (processInvoice === props.row.id ? true : false) : false"
              :variant="'transparent'"
              :opacity="0.50"
              :blur="'2px'"
              rounded="sm"
            >
              <!-- <b-link
                :class="props.row.customerIdSite ? (processInvoice === props.row.id ? '' : '') : 'disabled-link'"
                @click.prevent.stop="props.row.customerIdSite ? (processInvoice === props.row.id ? null : redirectToBilling(props.row.customerIdSite, props.row.id)) : null"
                :style="props.row.customerIdSite ? (processInvoice === props.row.id ? 'cursor: progress; color: #c1c1c1 !important;' : '') : ''"
              > -->
              <b-link
                :class="props.row.customerIdSite ? (processInvoice === props.row.id ? '' : '') : 'disabled-link'"
                @click.prevent.stop="props.row.customerIdSite ? (processInvoice === props.row.id ? null : logIdLicenseInvoice(props.row)) : null"
                :style="props.row.customerIdSite ? (processInvoice === props.row.id ? 'cursor: progress; color: #c1c1c1 !important;' : '') : ''"
              >
                {{props.row.payments}}
              </b-link>
            </b-overlay>
          </span>
          <span v-else-if="props.column.field == 'action'">
              <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  dropleft
                  no-caret
                  variant="flat-dark"
                  class="btn-icon dropdown-sites rounded-circle ml-1"
              >
                  <template #button-content class="pt-0">
                      <feather-icon icon="MoreVerticalIcon" class="mt-0 pt-0" size="20" />
                  </template>
                  <b-dropdown-item
                      link-class="d-flex align-items-center  item-dropdown-action-sites"
                      :style="rowsSites.length >= 1 && (rowsSites[0].permission === 'owner' || rowsSites[0].permission === 'admin') ? 'cursor: initial !important;' : 'cursor: not-allowed !important;'"
                      @click.prevent.stop="logIdReactivateSubscription(props.row, 'test')"
                      :disabled="rowsSites.length >= 1 && (rowsSites[0].permission === 'owner' || rowsSites[0].permission === 'admin') ? false : true"
                      v-if="props.row.cancelSub === true && props.row.nextDue && props.row.subscriptionId && props.formattedRow['plan'] !== 'free'"
                  >
                      <feather-icon
                          icon="RepeatIcon"
                          class="mr-50"
                          style="position: relative !important; left: -7px !important;"
                      />
                      Reactivate license
                  </b-dropdown-item>
                  <b-dropdown-item
                      link-class="d-flex align-items-center  item-dropdown-action-sites"
                      :style="rowsSites.length >= 1 && (rowsSites[0].permission === 'owner' || rowsSites[0].permission === 'admin') ? 'cursor: initial !important;' : 'cursor: not-allowed !important;'"
                      @click.prevent.stop="logIdLicense(props.row)"
                      :disabled="rowsSites.length >= 1 && (rowsSites[0].permission === 'owner' || rowsSites[0].permission === 'admin') ? false : true"
                  >
                      <feather-icon
                          icon="StarIcon"
                          class="mr-50"
                          style="position: relative !important; left: -7px !important;"
                      />
                      Upgrade/Downgrade
                  </b-dropdown-item>
                  <b-dropdown-item
                    link-class="d-flex align-items-center  item-dropdown-action-sites"
                    :style="props.row.customerIdSite ? (processInvoice === props.row.id ? 'cursor: progress !important; color: #c1c1c1 !important;' : '') : 'cursor: not-allowed !important;'"
                    @click.prevent.stop="props.row.customerIdSite ? (processInvoice === props.row.id ? null : redirectToBilling(props.row.customerIdSite, props.row.id)) : null"
                    :disabled="props.row.customerIdSite ? (processInvoice === props.row.id ? true : false) : true"
                  >
                      <feather-icon
                          icon="ListIcon"
                          class="mr-50"
                          style="position: relative !important; left: -7px !important;"
                      />
                      View details (stripe)
                  </b-dropdown-item>
                  <b-dropdown-item
                    link-class="d-flex align-items-center  item-dropdown-action-sites"
                    :style="props.row.customerIdSite ? (processInvoice === props.row.id ? 'cursor: progress !important; color: #c1c1c1 !important;' : '') : 'cursor: not-allowed !important;'"
                    @click.prevent.stop="props.row.customerIdSite ? (processInvoice === props.row.id ? null : logIdLicenseBilling(props.row)) : null"
                    :disabled="props.row.customerIdSite ? (processInvoice === props.row.id ? true : false) : true"
                  >
                      <feather-icon
                          icon="CreditCardIcon"
                          class="mr-50"
                          style="position: relative !important; left: -7px !important;"
                      />
                      Payment methods
                  </b-dropdown-item>
                  <b-dropdown-item
                    link-class="d-flex align-items-center  item-dropdown-action-sites"
                    :style="props.row.customerIdSite ? (processInvoice === props.row.id ? 'cursor: progress !important; color: #c1c1c1 !important;' : '') : 'cursor: not-allowed !important;'"
                    @click.prevent.stop="props.row.customerIdSite ? (processInvoice === props.row.id ? null : logIdLicenseInvoice(props.row)) : null"
                    :disabled="props.row.customerIdSite ? (processInvoice === props.row.id ? true : false) : true"
                  >
                      <feather-icon
                          icon="EyeIcon"
                          class="mr-50"
                          style="position: relative !important; left: -7px !important;"
                      />
                      View invoices
                  </b-dropdown-item>
                  <b-dropdown-item
                    link-class="d-flex align-items-center  item-dropdown-action-sites"
                    :style="props.row.cancelSub === true && props.row.nextDue && props.row.subscriptionId ? 'cursor: not-allowed !important;' : ''"
                    @click.prevent.stop="props.row.cancelSub === true && props.row.nextDue && props.row.subscriptionId ? null : (logIdCancelSubscription(props.row))"
                    :disabled="props.row.cancelSub === true && props.row.nextDue && props.row.subscriptionId ? true : false"
                    v-if="props.row.plan !== 'Free'"
                  >
                      <feather-icon
                          icon="XIcon"
                          class="mr-50"
                          style="position: relative !important; left: -7px !important;"
                      />
                      {{props.row.freeTrial === true && props.row.subscriptionId ? 'Cancel Free Trial' : 'Cancel subscription'}}
                  </b-dropdown-item>
                  <b-dropdown-item
                    link-class="d-flex align-items-center  item-dropdown-action-sites"
                    :style="rowsSites.length >= 1 && (rowsSites[0].permission === 'owner' || rowsSites[0].permission === 'admin') ? 'cursor: initial !important;' : 'cursor: not-allowed !important;'"
                    @click.prevent.stop="logIdActive(props.row)"
                    :disabled="rowsSites.length >= 1 && (rowsSites[0].permission === 'owner' || rowsSites[0].permission === 'admin') ? false : true"
                    v-if="props.row.statusSite === 'archive'"
                  >
                      <feather-icon
                          icon="ArchiveIcon"
                          class="mr-50"
                          style="position: relative !important; left: -7px !important;"
                      />
                      Activate Site
                  </b-dropdown-item>
                  <b-dropdown-item
                      link-class="d-flex align-items-center  item-dropdown-action-sites"
                      :style="rowsSites.length >= 1 && (rowsSites[0].permission === 'owner' || rowsSites[0].permission === 'admin') ? 'cursor: initial !important;' : 'cursor: not-allowed !important;'"
                      @click.prevent.stop="logIdArchive(props.row)"
                      :disabled="rowsSites.length >= 1 && (rowsSites[0].permission === 'owner' || rowsSites[0].permission === 'admin') ? false : true"
                      v-else
                  >
                      <feather-icon
                          icon="ArchiveIcon"
                          class="mr-50"
                          style="position: relative !important; left: -7px !important;"
                      />
                      Archive Site
                  </b-dropdown-item>
              </b-dropdown>
          </span>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown, BImgLazy, BButton,
  BRow, BCol, BProgress, BSpinner, BOverlay, BCardText, BCard, BLink,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { loadStripe } from '@stripe/stripe-js'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import InfoSubscription from '@/views/extensions/acl/table/sidebar/InfoSubscription.vue'
import YouLostAccessSidebar from '@/views/extensions/acl/table/sidebar/YouLostAccessSidebar.vue'
import SidebarOwnership from '@/views/extensions/acl/table/sidebar/SidebarOwnership.vue'
import ChangeProject from '@/views/extensions/acl/table/sidebar/ChangeProject.vue'
import ArchiveSite from '@/views/extensions/acl/table/sidebar/ArchiveSite.vue'
import ActivateSite from '@/views/extensions/acl/table/sidebar/ActivateSite.vue'
import MembersProject from '@/views/extensions/acl/table/sidebar/MembersProject.vue'
import InfoLicense from '@/views/extensions/acl/table/sidebar/InfoLicense.vue'
import CancelSubscription from '@/views/extensions/acl/table/sidebar/CancelSubscription.vue'
import ReactivateSubscription from '@/views/extensions/acl/table/sidebar/ReactivateSubscription.vue'
import SuccessChangeLicense from '@/views/extensions/acl/table/sidebar/SuccessChangeLicense.vue'
import ComingSoon from '@/views/extensions/acl/table/sidebar/ComingSoon.vue'
import 'vue-good-table/dist/vue-good-table.css'
import moment from 'moment'
import store from '@/store/index'
import Urlbox from 'urlbox'
import { db, auth, storage, dbErrors } from '@/firebase'
import { props } from 'vue-prism-component'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BImgLazy,
    BButton,
    BRow,
    BCol,
    BProgress,
    BSpinner,
    BOverlay,
    BCardText,
    BCard,
    BLink,
    vSelect,
    YouLostAccessSidebar,
    SidebarOwnership,
    ChangeProject,
    ArchiveSite,
    ActivateSite,
    MembersProject,
    InfoLicense,
    InfoSubscription,
    SuccessChangeLicense,
    CancelSubscription,
    ComingSoon,
    VueAutosuggest,
    ReactivateSubscription,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      proIcon: require('@/assets/images/icons/i-pro-badge.svg'),
      searchTerm: '',
      columnsFree: [
          // {
          //   label: '',
          //   field: 'imgSite',
          //   sortable: false,
          //   thClass: 'thTableSites',
          //   tdClass: 'tdTableSitesImg',
          // },
          {
              label: 'Site Name',
              field: 'name',
              sortable: false,
              thClass: 'thTableSitesNameLicense',
              tdClass: 'tdTableSitesNameLicense',
          },
          {
              label: 'URL',
              field: 'domain',
              sortable: false,
              thClass: 'thTableSitesDomainLicense',
              tdClass: 'tdTableSitesDomainLicense',
          },
          {
              label: 'Plan',
              field: 'currentplan',
              sortable: false,
              thClass: 'thTableSitesOwnedLicense',
              tdClass: 'tdTableSitesOwnedLicense',
          },
          {
              label: 'Upgrade',
              field: 'plan',
              sortable: false,
              thClass: 'thTableSitesViewListMembershipLicenseFree',
              tdClass: 'tdTableSitesViewListMembershipLicenseFree',
          },
          // {
          //     label: 'Pricing',
          //     field: 'amount',
          //     sortable: false,
          //     thClass: 'thTableSitesOwnedLicense',
          //     tdClass: 'tdTableSitesOwnedLicense',
          // },
          // {
          //     label: 'Due date',
          //     field: 'nextDue',
          //     sortable: false,
          //     thClass: 'thTableSitesOwnedLicense',
          //     tdClass: 'tdTableSitesOwnedLicense',
          // },
          {
              label: 'Status',
              field: 'status',
              sortable: false,
              thClass: 'thTableSitesOwnedLicenseRStatus',
              tdClass: 'tdTableSitesOwnedLicenseRStatus',
          },
          {
              label: 'Payments',
              field: 'payments',
              sortable: false,
              thClass: 'thTableSitesOwnedLicenseR',
              tdClass: 'tdTableSitesOwnedLicenseR',
          },
          {
              label: '',
              field: 'action',
              sortable: false,
              thClass: 'thTableSitesActionLicense',
              tdClass: 'tdTableSitesActionLicense',
          },
      ],
      columns: [
          // {
          //   label: '',
          //   field: 'imgSite',
          //   sortable: false,
          //   thClass: 'thTableSites',
          //   tdClass: 'tdTableSitesImg',
          // },
          {
              label: 'Site Name',
              field: 'name',
              sortable: false,
              thClass: 'thTableSitesNameLicense',
              tdClass: 'tdTableSitesNameLicense',
          },
          {
              label: 'URL',
              field: 'domain',
              sortable: false,
              thClass: 'thTableSitesDomainLicense',
              tdClass: 'tdTableSitesDomainLicense',
          },
          {
              label: 'Plan',
              field: 'currentplan',
              sortable: false,
              thClass: 'thTableSitesOwnedLicense',
              tdClass: 'tdTableSitesOwnedLicense',
          },
          {
              label: 'Pages',
              field: 'pages',
              sortable: false,
              thClass: 'thTableSitesOwnedLicense',
              tdClass: 'tdTableSitesOwnedLicense',
          },
          {
              label: 'Upgrade',
              field: 'plan',
              sortable: false,
              thClass: 'thTableSitesViewListMembershipLicense',
              tdClass: 'tdTableSitesViewListMembershipLicense',
          },
          {
              label: 'Price',
              field: 'amount',
              sortable: false,
              thClass: 'thTableSitesOwnedLicensePrice',
              tdClass: 'tdTableSitesOwnedLicensePrice',
          },
          {
              label: 'Due date',
              field: 'nextDue',
              sortable: false,
              thClass: 'thTableSitesOwnedLicense',
              tdClass: 'tdTableSitesOwnedLicense',
          },
          {
              label: 'Status',
              field: 'status',
              sortable: false,
              thClass: 'thTableSitesOwnedLicenseRStatus',
              tdClass: 'tdTableSitesOwnedLicenseRStatus',
          },
          {
              label: 'Payments',
              field: 'payments',
              sortable: false,
              thClass: 'thTableSitesOwnedLicenseR',
              tdClass: 'tdTableSitesOwnedLicenseR',
          },
          {
              label: '',
              field: 'action',
              sortable: false,
              thClass: 'thTableSitesActionLicense',
              tdClass: 'tdTableSitesActionLicense',
          },
      ],
      idsite: '',
      showOpenInfoLicense: false,
      showOpenActiveSite: false,
      showOpenArchiveSite: false,
      showOpenSuccessLicense: false,
      loadingSitesLicense: true,
      loadingInfiniteScroll: false,
      timerInfiniteSites: null,
      counterSites: 0,
      rowsSites: [],
      rowsSitesLazy: [],
      userData: {},
      isDevSite: false,
      showOpenComingSoon: false,
      showOpenCancelSubscription: false,
      showOpenReactivateSubscription: false,
      showOpenSubscription: false,
      processInvoice: '',
      arrayLicenses: []
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
  props: ['sitesFree', 'sitesBasic', 'sitesPro', 'sitesCustom', 'type', 'loadingSites'],
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    type(val) {
        this.loadingSitesLicense = true
        this.loadingInfiniteScroll = false
        this.rowsSites = []
        this.rowsSitesLazy = []
        if(val === 'Free') {
            // this.rowsSites = this.sitesFree
            var counterSitesFor = 0
            this.sitesFree.forEach(ele => {
                counterSitesFor += 1
                this.rowsSitesLazy.push(ele)
                if(counterSitesFor <= 10) {
                    // this.rowsSitesLazy.push(ele)
                    this.rowsSites.push(ele)
                }
            })
            this.counterSites = this.sitesFree.length
            var condicionParaOrdenar = (pageA, pageB) => {
                return pageA.statusSite.toLowerCase().localeCompare(pageB.statusSite.toLowerCase())
            }
            this.rowsSites.sort(condicionParaOrdenar)
            this.rowsSitesLazy.sort(condicionParaOrdenar)
            setTimeout(() => {
                this.loadingSitesLicense = false
            }, 700)
        } else if(val === 'Basic') {
            // this.rowsSites = this.sitesBasic
            var counterSitesFor = 0
            this.sitesBasic.forEach(ele => {
                counterSitesFor += 1
                this.rowsSitesLazy.push(ele)
                if(counterSitesFor <= 10) {
                    // this.rowsSitesLazy.push(ele)
                    this.rowsSites.push(ele)
                }
            })
            this.counterSites = this.sitesBasic.length
            var condicionParaOrdenar = (pageA, pageB) => {
                return pageA.statusSite.toLowerCase().localeCompare(pageB.statusSite.toLowerCase())
            }
            this.rowsSites.sort(condicionParaOrdenar)
            this.rowsSitesLazy.sort(condicionParaOrdenar)
            setTimeout(() => {
                this.loadingSitesLicense = false
            }, 700)
        } else if(val === 'Pro') {
            // this.rowsSites = this.sitesPro
            var counterSitesFor = 0
            this.sitesPro.forEach(ele => {
                counterSitesFor += 1
                this.rowsSitesLazy.push(ele)
                if(counterSitesFor <= 10) {
                    // this.rowsSitesLazy.push(ele)
                    this.rowsSites.push(ele)
                }
            })
            this.counterSites = this.sitesPro.length
            var condicionParaOrdenar = (pageA, pageB) => {
                return pageA.statusSite.toLowerCase().localeCompare(pageB.statusSite.toLowerCase())
            }
            this.rowsSites.sort(condicionParaOrdenar)
            this.rowsSitesLazy.sort(condicionParaOrdenar)
            setTimeout(() => {
                this.loadingSitesLicense = false
            }, 700)
        } else if(val === 'Custom') {
            // this.rowsSites = this.sitesCustom
            var counterSitesFor = 0
            this.sitesCustom.forEach(ele => {
                counterSitesFor += 1
                this.rowsSitesLazy.push(ele)
                if(counterSitesFor <= 10) {
                    // this.rowsSitesLazy.push(ele)
                    this.rowsSites.push(ele)
                }
            })
            this.counterSites = this.sitesCustom.length
            var condicionParaOrdenar = (pageA, pageB) => {
                return pageA.statusSite.toLowerCase().localeCompare(pageB.statusSite.toLowerCase())
            }
            this.rowsSites.sort(condicionParaOrdenar)
            this.rowsSitesLazy.sort(condicionParaOrdenar)
            setTimeout(() => {
                this.loadingSitesLicense = false
            }, 700)
        }

        // horizontal scroll
        // this.$nextTick(() => {
        //   $(".top-scroll-horizontal-license").scroll(() => {
        //     $(".vgt-responsive").scrollLeft($(".top-scroll-horizontal-license").scrollLeft());
        //   });

        //   $(".vgt-responsive").scroll(() => {
        //     $(".top-scroll-horizontal-license").scrollLeft($(".vgt-responsive").scrollLeft());
        //   });
        // });
    }
  },
  mounted() {
    this.timerInfiniteSites = setInterval(() => {
      this.constructInfiniteSites()
      // this.getWithScroll()
    }, 600)
  },
  destroyed() {
    clearInterval(this.timerInfiniteSites)
  },
  created() {
    localStorage.removeItem('reactivateUpg')
    localStorage.removeItem('cancelInfoLicense')
    this.processInvoice = ''
    this.rowsSites = []
    this.rowsSitesLazy = []
    this.loadingInfiniteScroll = false
    this.showOpenComingSoon = false
    this.showOpenSuccessLicense = false
    this.showOpenCancelSubscription = false
    this.showOpenReactivateSubscription = false
    this.showOpenSubscription = false
    this.isDevSite = false

    var urlWindow = window.location.href
    var urlWindowTransform = new URL(urlWindow)
    var urlWindowDomain = urlWindowTransform.hostname
    // console.log(urlWindowDomain)
    if(urlWindowDomain === 'app.seocloud.ai' || urlWindowDomain === 'localhost') {
      // this.isDevSite = true
    }

    dbErrors.collection('stripe').doc('plans').get()
    .then(docR => {
      this.arrayLicenses = docR.data().data
    })

    // this.$nextTick(() => {
    //   $(".top-scroll-horizontal-license").scroll(() => {
    //     $(".vgt-responsive").scrollLeft($(".top-scroll-horizontal-license").scrollLeft());
    //   });

    //   $(".vgt-responsive").scroll(() => {
    //     $(".top-scroll-horizontal-license").scrollLeft($(".vgt-responsive").scrollLeft());
    //   });
    // });

    var getSidebar = localStorage.getItem('licenseProcess')
    if(getSidebar) {
      setTimeout(() => {
        var parseJson = JSON.parse(getSidebar)
        // console.log(parseJson);
        this.logIdLicense(parseJson)
        localStorage.removeItem('licenseProcess')
      }, 1500)
    }

    const user = auth.currentUser
    if(user) {
      db.collection('Users').doc(user.uid).get()
      .then(userDoc => {
        this.userData = {}
        this.userData.id = userDoc.id
        this.userData.foto = userDoc.data().foto ? userDoc.data().foto : ''
        this.userData.name = userDoc.data().nombre ? userDoc.data().nombre : ''
        this.userData.status = userDoc.data().status ? userDoc.data().status : ''
        this.userData.email = userDoc.data().email ? userDoc.data().email : ''
        this.userData.teams = userDoc.data().teams ? userDoc.data().teams : ''

        setTimeout(() => {
          this.functionType()
        }, 1500)
      })
    }
  },
  methods: {
    getPlanAmount(annualyBill,customPlan,typeCrawls) {
      //  paidRecurrency === 'year' ? (planType === 5 ? '$'+123 : planType === 10 ? '$'+140 : planType === 25 ? '$'+195 : planType >= 50 ? '$'+(220 + (planType - 50)) : '$'+20) : (planType === 5 ? '$'+5 : planType === 10 ? '$'+8 : planType === 25 ? '$'+10 : planType >= 50 ? '$'+(12 + ((planType - 50) / 10)) : '$'+20) }}.00
      var typeCrawl = 0
      if(typeCrawls === 5 && (annualyBill === 'day' || annualyBill === 'Day' || annualyBill === 'Daily')) {
        typeCrawl = 5
      } else if(typeCrawls === 200 && (annualyBill === 'day' || annualyBill === 'Day' || annualyBill === 'Daily')) {
        typeCrawl = 6
      }
      else if(typeCrawls === 5) {
        typeCrawl = 1
      } else if(typeCrawls === 10) {
        typeCrawl = 2
      } else if(typeCrawls === 25) {
        typeCrawl = 3
      } else {
        typeCrawl = 4
      }

      var priceId = ''
      if(annualyBill) {
        // yearly
        if(typeCrawl === 5 || typeCrawl === '5') {
          priceId = 'price_1R2RrcEha01YHD9phZxV0VsQ'
        } else if(typeCrawl === 6 || typeCrawl === '6') {
          priceId = 'price_1R2RsSEha01YHD9phvjVLk9b'
        }

        else if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = 'price_1PbxnfEha01YHD9piIeM5vVS'
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = 'price_1PbxmUEha01YHD9pP485nQ7U'
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = 'price_1PePQVEha01YHD9prbkraO7a'
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((220 + (customPlan - 50)) === 220) {
            priceId = 'price_1PsDdHEha01YHD9pL55OW9S4'
          } else if((220 + (customPlan - 50)) === 270) {
            priceId = 'price_1PsDiIEha01YHD9p4iEAQG55'
          } else if((220 + (customPlan - 50)) === 320) {
            priceId = 'price_1PsDkTEha01YHD9pB3tBA7DS'
          } else if((220 + (customPlan - 50)) === 370) {
            priceId = 'price_1PsDnrEha01YHD9p871921P2'
          } else if((220 + (customPlan - 50)) === 420) {
            priceId = 'price_1PsDpuEha01YHD9pb9KPgKgS'
          } else if((220 + (customPlan - 50)) === 470) {
            priceId = 'price_1PsDrTEha01YHD9pe6nrSiij'
          } else if((220 + (customPlan - 50)) === 520) {
            priceId = 'price_1PsDsdEha01YHD9pgCfMsgji'
          } else if((220 + (customPlan - 50)) === 570) {
            priceId = 'price_1PsDtwEha01YHD9pPCk5ykJM'
          } else if((220 + (customPlan - 50)) === 620) {
            priceId = 'price_1PsDv0Eha01YHD9pNn5eB4ex'
          } else if((220 + (customPlan - 50)) === 670) {
            priceId = 'price_1PsDwTEha01YHD9pgzDwv3k1'
          } else if((220 + (customPlan - 50)) === 720) {
            priceId = 'price_1PsDxUEha01YHD9pg8Zuy2H5'
          } else if((220 + (customPlan - 50)) === 770) {
            priceId = 'price_1PsDz3Eha01YHD9p8lBWkiTs'
          } else if((220 + (customPlan - 50)) === 820) {
            priceId = 'price_1PsE0CEha01YHD9pJIonhW6i'
          } else if((220 + (customPlan - 50)) === 870) {
            priceId = 'price_1PsE1SEha01YHD9psjuJqVgG'
          } else if((220 + (customPlan - 50)) === 920) {
            priceId = 'price_1PsE3ZEha01YHD9pmH2n1KWg'
          } else if((220 + (customPlan - 50)) === 970) {
            priceId = 'price_1PsE4dEha01YHD9pV4ku6S0R'
          } else if((220 + (customPlan - 50)) === 1020) {
            priceId = 'price_1PsE5yEha01YHD9pA74l2pRb'
          } else if((220 + (customPlan - 50)) === 1070) {
            priceId = 'price_1PsE86Eha01YHD9p0WX3p7DT'
          } else if((220 + (customPlan - 50)) === 1120) {
            priceId = 'price_1PsE9YEha01YHD9pC21sQCvi'
          } else if((220 + (customPlan - 50)) === 1170) {
            priceId = 'price_1PsEAkEha01YHD9pD8bwdiAx'
          }
        }
      } else {
        // monthly
        if(typeCrawl === 5 || typeCrawl === '5') {
          priceId = 'price_1R2RrcEha01YHD9phZxV0VsQ'
        } else if(typeCrawl === 6 || typeCrawl === '6') {
          priceId = 'price_1R2RsSEha01YHD9phvjVLk9b'
        }
        
        else if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = 'price_1PsYAIEha01YHD9pFMqNcy4D'
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = 'price_1PsY9MEha01YHD9pdo4NDKoI'
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = 'price_1PsYAmEha01YHD9pstp3srF0'
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((12 + ((customPlan - 50) / 10)) === 12) {
            priceId = 'price_1PsChZEha01YHD9pUMdUmApc'
          } else if((12 + ((customPlan - 50) / 10)) === 17) {
            priceId = 'price_1PsCmFEha01YHD9pPbZrt36n'
          } else if((12 + ((customPlan - 50) / 10)) === 22) {
            priceId = 'price_1PsCpGEha01YHD9pnE3YcosF'
          } else if((12 + ((customPlan - 50) / 10)) === 27) {
            priceId = 'price_1PsCqsEha01YHD9pnQuGE1Tq'
          } else if((12 + ((customPlan - 50) / 10)) === 32) {
            priceId = 'price_1PsCsXEha01YHD9pzW1Gb5xU'
          } else if((12 + ((customPlan - 50) / 10)) === 37) {
            priceId = 'price_1PsCuPEha01YHD9pEru3TLFq'
          } else if((12 + ((customPlan - 50) / 10)) === 42) {
            priceId = 'price_1PsCwxEha01YHD9pDNZ7x3VD'
          } else if((12 + ((customPlan - 50) / 10)) === 47) {
            priceId = 'price_1PsCyeEha01YHD9pbKFWi2nt'
          } else if((12 + ((customPlan - 50) / 10)) === 52) {
            priceId = 'price_1PsCzxEha01YHD9p3946nYA4'
          } else if((12 + ((customPlan - 50) / 10)) === 57) {
            priceId = 'price_1PsD0wEha01YHD9pPJJwruw6'
          } else if((12 + ((customPlan - 50) / 10)) === 62) {
            priceId = 'price_1PsD2rEha01YHD9pMIcdlvUf'
          } else if((12 + ((customPlan - 50) / 10)) === 67) {
            priceId = 'price_1PsD5CEha01YHD9pnSjH6221'
          } else if((12 + ((customPlan - 50) / 10)) === 72) {
            priceId = 'price_1PsD7hEha01YHD9pPN9VReGi'
          } else if((12 + ((customPlan - 50) / 10)) === 77) {
            priceId = 'price_1PsDCHEha01YHD9pKKKXG09o'
          } else if((12 + ((customPlan - 50) / 10)) === 82) {
            priceId = 'price_1PsDDbEha01YHD9p1PQREKr7'
          } else if((12 + ((customPlan - 50) / 10)) === 87) {
            priceId = 'price_1PsDEKEha01YHD9ped00r5WZ'
          } else if((12 + ((customPlan - 50) / 10)) === 92) {
            priceId = 'price_1PsDFQEha01YHD9psGgej5B8'
          } else if((12 + ((customPlan - 50) / 10)) === 97) {
            priceId = 'price_1PsDGkEha01YHD9poqhAhxFW'
          } else if((12 + ((customPlan - 50) / 10)) === 102) {
            priceId = 'price_1PsDHzEha01YHD9pDW4vS1wZ'
          } else if((12 + ((customPlan - 50) / 10)) === 107) {
            priceId = 'price_1PsDIyEha01YHD9pMiJM1LvQ'
          }
        }
      }
      // console.log(priceId)
      var filterPrice = this.arrayLicenses.filter(ele => ele.priceId === priceId)
      var newPrice = filterPrice.length ? filterPrice[0].price : 0
      // console.log(filterPrice.length, newPrice, typeCrawl)

      var priceId = ''
      if(annualyBill === 'Daily') {
        // yearly
        if(typeCrawl === 5 || typeCrawl === '5') {
          priceId = '$' + (newPrice ? newPrice : 100)
        } else if(typeCrawl === 6 || typeCrawl === '6') {
          priceId = '$' + (newPrice ? newPrice : 200)
        }
      } else
      if(annualyBill === 'Anually') {
        // yearly
        if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = '$' + (newPrice ? newPrice : 19)
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = '$' + (newPrice ? newPrice : 29)
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = '$' + (newPrice ? newPrice : 49)
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((220 + (customPlan - 50)) === 220) {
            priceId = '$' + (newPrice ? newPrice : 69)
          } else if((220 + (customPlan - 50)) === 270) {
            priceId = '$' + (newPrice ? newPrice : 99)
          } else if((220 + (customPlan - 50)) === 320) {
            priceId = '$' + (newPrice ? newPrice : 139)
          } else if((220 + (customPlan - 50)) === 370) {
            priceId = '$' + (newPrice ? newPrice : 179)
          } else if((220 + (customPlan - 50)) === 420) {
            priceId = '$' + (newPrice ? newPrice : 219)
          } else if((220 + (customPlan - 50)) === 470) {
            priceId = '$' + (newPrice ? newPrice : 259)
          } else if((220 + (customPlan - 50)) === 520) {
            priceId = '$' + (newPrice ? newPrice : 299)
          } else if((220 + (customPlan - 50)) === 570) {
            priceId = '$' + (newPrice ? newPrice : 339)
          } else if((220 + (customPlan - 50)) === 620) {
            priceId = '$' + (newPrice ? newPrice : 379)
          } else if((220 + (customPlan - 50)) === 670) {
            priceId = '$' + (newPrice ? newPrice : 419)
          } else if((220 + (customPlan - 50)) === 720) {
            priceId = '$' + (newPrice ? newPrice : 459)
          } else if((220 + (customPlan - 50)) === 770) {
            priceId = '$' + (newPrice ? newPrice : 499)
          } else if((220 + (customPlan - 50)) === 820) {
            priceId = '$' + (newPrice ? newPrice : 539)
          } else if((220 + (customPlan - 50)) === 870) {
            priceId = '$' + (newPrice ? newPrice : 579)
          } else if((220 + (customPlan - 50)) === 920) {
            priceId = '$' + (newPrice ? newPrice : 619)
          } else if((220 + (customPlan - 50)) === 970) {
            priceId = '$' + (newPrice ? newPrice : 659)
          } else if((220 + (customPlan - 50)) === 1020) {
            priceId = '$' + (newPrice ? newPrice : 699)
          } else if((220 + (customPlan - 50)) === 1070) {
            priceId = '$' + (newPrice ? newPrice : 739)
          } else if((220 + (customPlan - 50)) === 1120) {
            priceId = '$' + (newPrice ? newPrice : 779)
          } else if((220 + (customPlan - 50)) === 1170) {
            priceId = '$' + (newPrice ? newPrice : 819)
          }
        }
      } else {
        // monthly
        if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = '$' + (newPrice ? newPrice : 25)
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = '$' + (newPrice ? newPrice : 39)
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = '$' + (newPrice ? newPrice : 65)
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((12 + ((customPlan - 50) / 10)) === 12) {
            priceId = '$' + (newPrice ? newPrice : 89)
          } else if((12 + ((customPlan - 50) / 10)) === 17) {
            priceId = '$' + (newPrice ? newPrice : 129)
          } else if((12 + ((customPlan - 50) / 10)) === 22) {
            priceId = '$' + (newPrice ? newPrice : 179)
          } else if((12 + ((customPlan - 50) / 10)) === 27) {
            priceId = '$' + (newPrice ? newPrice : 229)
          } else if((12 + ((customPlan - 50) / 10)) === 32) {
            priceId = '$' + (newPrice ? newPrice : 279)
          } else if((12 + ((customPlan - 50) / 10)) === 37) {
            priceId = '$' + (newPrice ? newPrice : 329)
          } else if((12 + ((customPlan - 50) / 10)) === 42) {
            priceId = '$' + (newPrice ? newPrice : 379)
          } else if((12 + ((customPlan - 50) / 10)) === 47) {
            priceId = '$' + (newPrice ? newPrice : 429)
          } else if((12 + ((customPlan - 50) / 10)) === 52) {
            priceId = '$' + (newPrice ? newPrice : 479)
          } else if((12 + ((customPlan - 50) / 10)) === 57) {
            priceId = '$' + (newPrice ? newPrice : 529)
          } else if((12 + ((customPlan - 50) / 10)) === 62) {
            priceId = '$' + (newPrice ? newPrice : 579)
          } else if((12 + ((customPlan - 50) / 10)) === 67) {
            priceId = '$' + (newPrice ? newPrice : 629)
          } else if((12 + ((customPlan - 50) / 10)) === 72) {
            priceId = '$' + (newPrice ? newPrice : 679)
          } else if((12 + ((customPlan - 50) / 10)) === 77) {
            priceId = '$' + (newPrice ? newPrice : 729)
          } else if((12 + ((customPlan - 50) / 10)) === 82) {
            priceId = '$' + (newPrice ? newPrice : 779)
          } else if((12 + ((customPlan - 50) / 10)) === 87) {
            priceId = '$' + (newPrice ? newPrice : 829)
          } else if((12 + ((customPlan - 50) / 10)) === 92) {
            priceId = '$' + (newPrice ? newPrice : 879)
          } else if((12 + ((customPlan - 50) / 10)) === 97) {
            priceId = '$' + (newPrice ? newPrice : 929)
          } else if((12 + ((customPlan - 50) / 10)) === 102) {
            priceId = '$' + (newPrice ? newPrice : 979)
          } else if((12 + ((customPlan - 50) / 10)) === 107) {
            priceId = '$' + (newPrice ? newPrice : 999)
          }
        }
      }
      // console.log(priceId);

      return priceId
    },
    constructInfiniteSites() {
      var listElm = document.querySelector('#app');
      let bottomOfWindow = document.documentElement.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 200
      if(this.rowsSitesLazy.length >= 1) {
        if(bottomOfWindow) {
          // console.log('first', this.rowsSites.length !== this.rowsSitesLazy.length, this.loadingInfiniteScroll)
          if(!this.loadingInfiniteScroll && this.rowsSites.length !== this.rowsSitesLazy.length) {
            this.loadingInfiniteScroll = true
            var counterFound = 0
            this.rowsSitesLazy.forEach(ele => {
              var alreadyAdd = this.rowsSites.filter(item => item.id === ele.id)
              if(alreadyAdd.length === 0) {
                if(counterFound <= 9) {
                  counterFound += 1
                  this.rowsSites.push(ele)
                }
              }
            })

            setTimeout(() => {
              this.loadingInfiniteScroll = false
            }, 1000)
          }
        }
      }

      var getSuccessLicense = localStorage.getItem('successLicense')
      if(getSuccessLicense) {
        // console.log('cancel sub');
        var parseLicenseSuccess = JSON.parse(getSuccessLicense)
        localStorage.removeItem('successLicense')
        this.logSuccessLicense(parseLicenseSuccess)
      }

      var getCancelLicense = localStorage.getItem('cancelInfoLicense')
      if(getCancelLicense) {
        // console.log('cancel sub');
        var parseLicenseSuccess = JSON.parse(getCancelLicense)
        localStorage.removeItem('cancelInfoLicense')
        this.logIdCancelSubscription(parseLicenseSuccess)
      }

      var openInfoLicense = localStorage.getItem('jsonInfoLic')
      if(openInfoLicense) {
        var atomPlanParse = JSON.parse(openInfoLicense)
        this.idsite = atomPlanParse
        this.showOpenInfoLicense = true
        localStorage.removeItem('jsonInfoLic')
      }

      var getSidebar = localStorage.getItem('licenseProcess')
      if(getSidebar) {
        setTimeout(() => {
          var parseJson = JSON.parse(getSidebar)
          // console.log(parseJson);
          this.logIdLicense(parseJson)
          localStorage.removeItem('licenseProcess')
        }, 1500)
      }
    },
    logIdSite(val) {
      // console.log(val)
      this.idsite = val
    //   this.showOpen = true
    },
    logIdChangeProject(val) {
      // console.log('first')
      this.idsite = val
    //   this.showOpenChangeProject = true
    },
    logIdArchive(val) {
      this.idsite = val
      this.showOpenArchiveSite = true
    },
    logIdActive(val) {
      this.idsite = val
      this.showOpenActiveSite = true
    },
    logIdMembersProject(val) {
      // this.idsite = val
      // this.showOpenMembersProject = true
    },
    logSuccessLicense(val) {
      // console.log('object', this.showOpenSuccessLicense);
      this.idsite = val
      this.showOpenSuccessLicense = true
    },
    logIdLicense(val) {
      // console.log(val);
      this.idsite = val
      this.showOpenInfoLicense = true
      // console.log(val);
      // localStorage.setItem('successLicense', JSON.stringify(val))
    },
    logIdLicenseInvoice(val) {
      // console.log(val);
      val.viewInvoice = true
      this.idsite = val
      this.showOpenSubscription = true
      // console.log(val);
      // localStorage.setItem('successLicense', JSON.stringify(val))
    },
    logIdLicenseBilling(val) {
      // console.log(val);
      val.viewBilling = true
      this.idsite = val
      this.showOpenSubscription = true
      // console.log(val);
      // localStorage.setItem('successLicense', JSON.stringify(val))
    },
    onRowSelected(items) {
      // console.log(items)
      this.idsite = items.row
      this.showOpenSubscription = true
    },
    logIdCancelSubscription(val) {
      // localStorage.setItem('successLicense', JSON.stringify(val))
      this.idsite = val
      this.showOpenCancelSubscription = true
    },
    logIdInfoSubscription(val) {
      this.idsite = val
      this.showOpenSubscription = true
    },
    logIdReactivateSubscription(val,option) {
      // localStorage.setItem('successLicense', JSON.stringify(val))
      if(option === 'upgrade') {
        localStorage.setItem('reactivateUpg', true)
      }
      
      this.idsite = val
      this.showOpenReactivateSubscription = true
    },
    logIdComingSoon(val) {
      this.idsite = val
      this.showOpenComingSoon = true
    },
    getWithScroll() {
      // setTimeout(() => {
      //   const primerElemento = document.querySelector('.table-wproject-sites-license');
      //   const segundoElemento = document.getElementById('tab-err-sc2');

      //   if(primerElemento) {
      //     console.log(primerElemento.offsetWidth, segundoElemento);
      //     const ancho = primerElemento.offsetWidth; // Obtiene el ancho del primer elemento
      //     segundoElemento.style.width = ancho + 'px';
      //   }
      // }, 500)
    },
    functionType() {
      this.loadingSitesLicense = true
      this.loadingInfiniteScroll = false
      this.rowsSites = []
      this.rowsSitesLazy = []
      if(this.type === 'Free') {
        // this.rowsSites = this.sitesFree
        var counterSitesFor = 0
        this.sitesFree.forEach(ele => {
            counterSitesFor += 1
            this.rowsSitesLazy.push(ele)
            if(counterSitesFor <= 10) {
                // this.rowsSitesLazy.push(ele)
                this.rowsSites.push(ele)
            }
        })
        this.counterSites = this.sitesFree.length
        var condicionParaOrdenar = (pageA, pageB) => {
            return pageA.statusSite.toLowerCase().localeCompare(pageB.statusSite.toLowerCase())
        }
        this.rowsSites.sort(condicionParaOrdenar)
        this.rowsSitesLazy.sort(condicionParaOrdenar)
        setTimeout(() => {
            this.loadingSitesLicense = false
        }, 700)
      } else if(this.type === 'Basic') {
        // this.rowsSites = this.sitesBasic
        var counterSitesFor = 0
        this.sitesBasic.forEach(ele => {
            counterSitesFor += 1
            this.rowsSitesLazy.push(ele)
            if(counterSitesFor <= 10) {
                // this.rowsSitesLazy.push(ele)
                this.rowsSites.push(ele)
            }
        })
        this.counterSites = this.sitesBasic.length
        var condicionParaOrdenar = (pageA, pageB) => {
            return pageA.statusSite.toLowerCase().localeCompare(pageB.statusSite.toLowerCase())
        }
        this.rowsSites.sort(condicionParaOrdenar)
        this.rowsSitesLazy.sort(condicionParaOrdenar)
        setTimeout(() => {
            this.loadingSitesLicense = false
        }, 700)
      } else if(this.type === 'Pro') {
        // this.rowsSites = this.sitesPro
        var counterSitesFor = 0
        this.sitesPro.forEach(ele => {
            counterSitesFor += 1
            this.rowsSitesLazy.push(ele)
            if(counterSitesFor <= 10) {
                // this.rowsSitesLazy.push(ele)
                this.rowsSites.push(ele)
            }
        })
        this.counterSites = this.sitesPro.length
        var condicionParaOrdenar = (pageA, pageB) => {
            return pageA.statusSite.toLowerCase().localeCompare(pageB.statusSite.toLowerCase())
        }
        this.rowsSites.sort(condicionParaOrdenar)
        this.rowsSitesLazy.sort(condicionParaOrdenar)
        setTimeout(() => {
            this.loadingSitesLicense = false
        }, 700)
      } else if(this.type === 'Custom') {
        // this.rowsSites = this.sitesCustom
        var counterSitesFor = 0
        this.sitesCustom.forEach(ele => {
            counterSitesFor += 1
            this.rowsSitesLazy.push(ele)
            if(counterSitesFor <= 10) {
                // this.rowsSitesLazy.push(ele)
                this.rowsSites.push(ele)
            }
        })
        this.counterSites = this.sitesCustom.length
        var condicionParaOrdenar = (pageA, pageB) => {
            return pageA.statusSite.toLowerCase().localeCompare(pageB.statusSite.toLowerCase())
        }
        this.rowsSites.sort(condicionParaOrdenar)
        this.rowsSitesLazy.sort(condicionParaOrdenar)
        setTimeout(() => {
            this.loadingSitesLicense = false
        }, 700)
      }

      // horizontal scroll
      // this.$nextTick(() => {
      //   $(".top-scroll-horizontal-license").scroll(() => {
      //     $(".vgt-responsive").scrollLeft($(".top-scroll-horizontal-license").scrollLeft());
      //   });

      //   $(".vgt-responsive").scroll(() => {
      //     $(".top-scroll-horizontal-license").scrollLeft($(".vgt-responsive").scrollLeft());
      //   });
      // });
    },
    getError(val) {
    //   this.$router.push({
    //     name: 'view-errors',
    //     params: {
    //       id: val.id, imgSite: val.imgSite, name: val.name, url: val.domain, project: val.projectid, own: 'me'
    //     },
    //   })
    },
    getSite(val){
      // console.log(val)
    //   if(val.id) {
    //     if(val.id && val.own === 'other' && val.invitation.statusInv === 'sended') {
    //       this.logIdAcceptInvitation(val)
    //     } else if(val.status === 'archive') {
    //       this.logIdActive(val)
    //     } else {
    //       this.$router.push({ name: 'view-errors', params: {id: val.id, imgSite: val.img, name: val.name, url: val.domain, project: val.projectid, own: val.own} })
    //     }
    //   }
    },
    reactivateSubscription(idsitePayload) {
      let config = {
        method: 'post',
        // maxBodyLength: Infinity,
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/reactivate-subscription',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({subscriptionId: idsitePayload.subscriptionId})
      };
  
      this.axios(config)
      .then((response) => {
        console.log(response.data);
        db.collection('Projects').doc(idsitePayload.projectid).collection('sites').doc(idsitePayload.id).update({
          // plan: 'free',
          // planType: '0',
          // due: null
          cancelSub: false,
          downgrade_date: '',
          downgrade_plan: ''
          // cancelationReason: 'Downgrade subscription'
        })

        this.$router.push({
          name: 'view-errors',
          query:{
            lic: 'upd'
          },
          params: {
            id: idsitePayload.id, imgSite: idsitePayload.img, name: idsitePayload.name, url: idsitePayload.domainFixed, project: idsitePayload.projectid, own: 'me'
          },
        })
      })
      .catch(e => {
        console.log(e.message);
      })
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Key copied',
          icon: 'BellIcon',
          variant: 'success',
        },
      })
    },
    redirectToBilling(customerId, idsite) {
      // this.processInvoice = customerId
      this.processInvoice = idsite
      var URLactual = new URL(window.location)
      var domain = URLactual.protocol + '//' + (URLactual.hostname === 'localhost' ? URLactual.hostname + ':8080' : URLactual.hostname)
      // const stripe = await loadStripe('sk_test_51PVKa9Eha01YHD9pNslDNQ1kuqFaEeF0tpDlaoVDEi6tNIRUU0pzEcCkkWovrVDr9rXvbdmJxAD0kLEmAC1GIt4G00uIJjXPQK');
      
      // Aquí llamas a tu backend para obtener el enlace de facturación
      // const response = await fetch('https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/create-billing-session', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({ customerId: 'cus_QVUpm1tcsGouMe', domain: domain})
      // });

      // const { url } = await response.json();

      let config = {
        method: 'post',
        // maxBodyLength: Infinity,
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/create-billing-session',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({customerId: customerId, domain: domain})
      };
  
      this.axios(config)
      .then((response) => {
        // Redirigir al usuario a la URL de facturación de Stripe
        // window.location.href = response
        // console.log(response.data.url);
        var url = response.data.url
        if(url) {
          window.location.href = url

          setTimeout(() => {
            this.processInvoice = ''
          }, 1500)
        }
      })
      .catch(e => console.log(e.message))

      // // Redirigir al usuario a la URL de facturación de Stripe
      // window.location.href = url;
    },
  }
}
</script>

<style>
.disabled-link {
  color: #acacac !important;
  cursor: not-allowed !important;
}
.disabled-link:hover {
  color: #bdbdbd !important;
}
.table-wproject-sites-licenseFree {
  margin-bottom: 160px;
}
.table-wproject-sites-licenseFree td {
  padding: .6em .1em .6em .1em !important;
}
.table-wproject-sites-licenseFree tr:hover {
  background-color: #f6f6f9 !important;
}
.table-wproject-sites-licensePro {
  margin-bottom: 160px;
}
.table-wproject-sites-licensePro td {
  padding: .6em .1em .6em .1em !important;
}
.table-wproject-sites-licensePro tr:hover {
  background-color: #f6f6f9 !important;
}
.autosuggest-sites .autosuggest__results-container {
  max-width: 350px !important;
}
.thTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  padding-right: 0.75em !important;
}
.tdTableSitesImg {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  width: 165px !important;
  max-width: 165px !important;
  /* margin: 20px !important; */
}
.tdTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  /* margin: 20px !important; */
}
.thTableSitesNameLicense {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  padding-right: 0.75em !important;
  max-width: 255px !important;
  width: 255px !important;
  min-width: 200px !important;
}
.tdTableSitesNameLicense {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  /* cursor: pointer; */
  vertical-align: middle !important;
  max-width: 255px !important;
  width: 255px !important;
  min-width: 200px !important;
  cursor: pointer;
}
.thTableSitesViewListMembershipLicenseFree {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  text-align: center !important;
  /* padding-right: 0.75em !important; */
  padding-right: 30px !important;
  max-width: 120px !important;
  width: 120px !important;
}
.tdTableSitesViewListMembershipLicenseFree {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  /* cursor: pointer; */
  vertical-align: middle !important;
  padding-right: 30px !important;
  /* margin: 20px !important; */
  max-width: 120px !important;
  width: 120px !important;
}
.thTableSitesViewListMembershipLicense {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  text-align: center !important;
  /* padding-right: 0.75em !important; */
  padding-right: 30px !important;
  max-width: 120px !important;
  width: 120px !important;
  min-width: 110px !important;
}
.tdTableSitesViewListMembershipLicense {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  /* cursor: pointer; */
  vertical-align: middle !important;
  padding-right: 30px !important;
  /* margin: 20px !important; */
  max-width: 120px !important;
  width: 120px !important;
  min-width: 110px !important;
}
.thTableSitesViewListKey {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  max-width: 112px !important;
  width: 112px !important;
  text-align: center !important;
  padding-right: 0.75em !important;
}
.tdTableSitesKeyViewListKey {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  /* cursor: pointer; */
  vertical-align: middle !important;
  max-width: 112px !important;
  width: 112px !important;
  /* text-align: center; */
}
.thTableSitesViewListErrors {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  max-width: 165px !important;
  width: 165px !important;
  text-align: center !important;
  padding-right: 0.75em !important;
}
.thTableSitesViewListErrors span {
  margin-right: 10px !important;
}
.tdTableSitesViewListErrors {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  /* cursor: pointer; */
  vertical-align: middle !important;
  max-width: 165px !important;
  width: 165px !important;
  text-align: center !important;
  /* margin: 20px !important; */
}
.thTableSitesViewList {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  max-width: 145px !important;
  width: 145px !important;
  text-align: center !important;
  padding-right: 0.75em !important;
}
.tdTableSitesViewList {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  /* cursor: pointer; */
  vertical-align: middle !important;
  max-width: 145px !important;
  width: 145px !important;
  text-align: center !important;
  /* margin: 20px !important; */
}
.tdTableSitesKeyViewList {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  max-width: 132px !important;
  width: 132px !important;
  text-align: center !important;
}
.thTableSitesDomainLicense {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  /* max-width: 425px; */
  font-weight: 300;
  padding-right: 0.75em !important;
  min-width: 300px !important;
}
.tdTableSitesDomainLicense {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  /* cursor: pointer; */
  /* max-width: 425px; */
  word-wrap: break-word;
  vertical-align: middle !important;
  min-width: 300px !important;
  /* margin: 20px !important; */
}
.thTableSitesOwnedLicense {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  text-align: left;
  /* max-width: 425px; */
  font-weight: 300;
  padding-right: 0.75em !important;
  padding-left: 0.5rem !important;
  /* margin: 20px !important; */
  width: 100px !important;
  max-width: 100px !important;
}
.tdTableSitesOwnedLicense {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  width: 100px !important;
  max-width: 100px !important;
  padding-left: 0.5rem !important;
  text-align: left;
  cursor: pointer;
}
.thTableSitesOwnedLicenseR {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  /* max-width: 425px; */
  font-weight: 300;
  padding-right: 0.75em !important;
  /* margin: 20px !important; */
  width: 100px !important;
  max-width: 100px !important;
}
.thTableSitesOwnedLicensePrice {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  text-align: left;
  /* max-width: 425px; */
  font-weight: 300;
  padding-right: 0.75em !important;
  padding-left: 0.5rem !important;
  /* margin: 20px !important; */
  width: 120px !important;
  max-width: 120px !important;
  min-width: 120px !important;
}
.tdTableSitesOwnedLicensePrice {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  width: 120px !important;
  max-width: 120px !important;
  min-width: 120px !important;
  padding-left: 0.5rem !important;
  text-align: left;
  cursor: pointer;
}
.tdTableSitesOwnedLicenseR {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  width: 100px !important;
  max-width: 100px !important;
  text-align: center !important;
}
.thTableSitesOwnedLicenseRStatus {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  /* max-width: 425px; */
  font-weight: 300;
  padding-right: 0.75em !important;
  /* margin: 20px !important; */
  width: 100px !important;
  max-width: 170px !important;
}
.tdTableSitesOwnedLicenseRStatus {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  width: 100px !important;
  max-width: 170px !important;
  text-align: center !important;
  cursor: pointer;
}
.thTableSitesActionLicense {
  background: transparent !important;
  border: 1px solid transparent !important;
  /* border-bottom: 1px solid #DCDCF2 !important; */
  /* cursor: pointer; */
  vertical-align: middle !important;
  width: 50px !important;
  max-width: 50px !important;
  /* margin: 20px !important; */
}
.tdTableSitesActionLicense {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  width: 50px !important;
  max-width: 50px !important;
}
.vgt-table.bordered th {
  background: #fff !important;
  border: 1px solid transparent !important;
  margin-top: 20px !important;
  /* font-size: 16px !important; */
  /* color: #181E38 !important; */
}
.item-dropdown-action-sites:hover {
  background: none !important;
  background-color: none !important;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #fff;
  border: none;
  padding: 0px !important;
  /* color: #394066; */
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
  padding: 0px !important;
}
.style-chooser .dropdown-toggle {
  background: transparent !important;
  border: 0px !important;
  padding: 0px !important;
  color: #656B85 !important;
}
.style-chooser .dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' stroke='' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.style-chooser .dropdown-item {
  padding: 0px 5px;
}
.style-chooser .dropdown-menu {
  border-radius: 10px;
  border: 1px solid #bbbbb9 !important;
  width: 180px !important;
}
.dropdown-sites button {
  padding: 0px !important;
}
.dropdown-sites button:hover {
  background: transparent !important;
}
.autosuggest-sites > div > input {
  width: 325px;
  height: 44px;
  border: 1px solid #A7AAB9;
  border-radius: 22px;
}
.autosuggest-sites.autosuggest__results-container {
  background: #fff !important;
}
.table-show-license .vgt-responsive {
  overflow-x: auto !important;
}
.table-show-license .vgt-responsive .vgt-table {
  /* overflow-x: auto !important; */
  font-size: 15px !important;
}
.table-show-license tbody td {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
/* .vgt-table.bordered th {
  border: 1px solid #dcdfe6;
} */
/* .vgt-table thead th {
  border-bottom: 1px solid #dcdfe6;
  background: linear-gradient(#f4f5f8,#f1f3f6);
} */
@media (max-width: 768px) {
  .search-input-license {
    max-width: 175px !important;
    width: 175px !important;
    float: left !important;
  }
  .amount-license {
    width: 100%;
  }
}
</style>